import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import { useLazyQuery } from 'react-apollo';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ICalendarResource } from 'model/calendar/filters';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { GET_ATTENDEES } from 'api/graphql/v2/queries/Attendees';
import CalendarFilters from '../filters';
import { SearchWrapper, CalendarFiltersAndSearchWrapper } from './style';
import Loader from 'views/components/ui/content/Loader';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import {
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar,
  PlannerView
} from 'utils/cache/calendar';
import { useReactiveVar } from '@apollo/client';

import { calendarGroup } from 'model/calendar/groups';
import useEventsFetcherHook from 'hooks/eventsFetcherHook';
import {
  searchedClientsPrevVar,
  searchedProvidersPrefVar
} from 'utils/cache/filters';

import {
  EVENTS_FETCHING_ACTIONS,
  plannerContext
} from 'pages/MainCalendarPage';
import {
  getClientEventsVariables,
  getSearchedStaffEventsVariables
} from 'utils/mappers/request/events';
import { alphabeticalSort } from 'utils/sort';
import { calendarFiltersPrefVar } from 'utils/cache/filters';
import { internalFilters } from 'utils/constants/calendarFilters';

import {
  convertFilteredToSearched,
  isAttendeeInFiltered
} from 'helpers/calendarHelper';

interface Props {
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
}
const CalendarFiltersAndSearch: React.FC<Props> = ({
  paginationArray,
  setPaginationArray
}: Props) => {
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const [list, setList] = useState<ICalendarResource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [menuShown, setMenuShown] = useState<boolean>(false);
  const [selectedAttendee, setSelectedAttendee] = useState<any | undefined>(
    undefined
  );
  const selectClientRef = useRef<any>(null);
  const selectProviderRef = useRef<any>(null);
  const searchedClients = useReactiveVar(searchedClientsPrevVar);
  const searchedProviders = useReactiveVar(searchedProvidersPrefVar);
  const {
    setIsLoading: setLoadingEvents,
    setIsCalendarLoading,
    isCalendarLoading,
    isBulkCancelMode
  } = useContext(plannerContext);

  const { getStaffEvents, getClientEvents } = useEventsFetcherHook(
    [selectedAttendee],
    setLoadingEvents,
    setIsCalendarLoading,
    isCalendarLoading,
    EVENTS_FETCHING_ACTIONS.APPLY_SEARCH,
    setPaginationArray,
    paginationArray,
    [selectedAttendee],
    [selectedAttendee]
  );

  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);

  const [doRequest, { data: attendeesList }] = useLazyQuery(GET_ATTENDEES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const nonDisplayedAttendees = attendeesList?.attendees
        ?.filter((attendee: ICalendarResource) => {
          return (
            !searchedClients.has(attendee.id) &&
            !searchedProviders.has(attendee.id)
          );
        })
        .sort((a1: ICalendarResource, a2: ICalendarResource) =>
          alphabeticalSort(a1.displayName, a2.displayName)
        );
      setList(nonDisplayedAttendees);
      setIsLoading(false);
    }
  });

  const handleClientSearch = useCallback(
    (query: any) => {
      setIsLoading(true);
      doRequest({
        variables: {
          name: query?.trim(),
          isClient: true,
          isStaffPage: true,
          shouldExcludeActive: true
        }
      });
    },
    [doRequest]
  );

  const handleProviderSearch = useCallback(
    (query: any) => {
      setIsLoading(true);
      doRequest({
        variables: {
          name: query?.trim(),
          isClient: false,
          isStaffPage: true,
          shouldExcludeActive: true
        }
      });
    },
    [doRequest]
  );

  const handleInputChange = useCallback((input: string) => {
    input.length < 2 ? setMenuShown(false) : setMenuShown(true);
    setIsLoading(true);
    setList([]);
  }, []);

  const handleAttendeeChange = useCallback(
    data => {
      selectClientRef?.current?.clear();
      selectProviderRef?.current?.clear();
      //if input is cleared
      if (data?.length === 0) return;

      //handle converting filtered to searched
      if (isAttendeeInFiltered(data[0].id)) {
        const shouldFetchEvents = convertFilteredToSearched(data[0]);
        if (!shouldFetchEvents) return;
      }

      const selectedAttendee = data[0];
      setSelectedAttendee(selectedAttendee);
      const isCanceledFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.canceled
      );
      const isDeletedFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.deleted
      );
      if (selectedAttendee?.attendeeType === calendarGroup.client) {
        searchedClientsPrevVar(
          searchedClients.set(selectedAttendee.id, selectedAttendee)
        );
        getClientEvents(
          getClientEventsVariables(
            [selectedAttendee],
            calendarDate,
            calendarView,
            isCanceledFilter,
            isDeletedFilter
          )
        );
      } else {
        searchedProvidersPrefVar(
          searchedProviders.set(selectedAttendee.id, selectedAttendee)
        );
        getStaffEvents(
          getSearchedStaffEventsVariables(
            [selectedAttendee],
            calendarDate,
            calendarView,
            isCanceledFilter,
            isDeletedFilter
          )
        );
      }
    },
    [
      filters.internals,
      searchedClients,
      getClientEvents,
      calendarDate,
      calendarView,
      searchedProviders,
      getStaffEvents
    ]
  );
  return (
    <CalendarFiltersAndSearchWrapper>
      <SearchWrapper isSearchListOpen={menuShown} view={calendarView}>
        <img src={searchIcon} className="search-icon" alt="" />
        <AsyncTypeahead
          disabled={isBulkCancelMode}
          className="client-Provider_wrapper"
          filterBy={() => true}
          ref={selectClientRef}
          id="search-attendees"
          labelKey="displayName"
          isLoading={isLoading}
          minLength={2}
          onSearch={handleClientSearch}
          options={list}
          placeholder={'Client'}
          onChange={(data: ICalendarResource[]) => {
            handleAttendeeChange(data);
          }}
          delay={500}
          renderMenuItemChildren={option => (
            <p className="client-Provider_option">{option.displayName}</p>
          )}
          emptyLabel={<p className="client-Provider_results">No results</p>}
          promptText={
            <p className="client-Provider_search">Type to search...</p>
          }
          searchText={
            <p className="client-Provider_loader">
              <Loader />
            </p>
          }
          onInputChange={handleInputChange}
          useCache={false} //to remove any cached values that may be added to calendar already
        />
        <img src={searchIcon} className="search-icon-provider" alt="" />
        <AsyncTypeahead
          disabled={isBulkCancelMode}
          className="client-Provider_wrapper Provider_Wrapper"
          filterBy={() => true}
          ref={selectProviderRef}
          id="search-attendees"
          labelKey="displayName"
          isLoading={isLoading}
          minLength={2}
          onSearch={handleProviderSearch}
          options={list}
          placeholder={'Provider'}
          onChange={(data: ICalendarResource[]) => {
            handleAttendeeChange(data);
          }}
          delay={500}
          renderMenuItemChildren={option => (
            <p className="client-Provider_option">{option.displayName}</p>
          )}
          emptyLabel={<p className="client-Provider_results">No results</p>}
          promptText={
            <p className="client-Provider_search">Type to search...</p>
          }
          searchText={
            <p className="client-Provider_loader">
              <Loader />
            </p>
          }
          onInputChange={handleInputChange}
          useCache={false} //to remove any cached values that may be added to calendar already
        />
        <CalendarFilters />
      </SearchWrapper>
      {calendarView === PlannerView.plannerWeek && (
        <div className="empty-slot" />
      )}
    </CalendarFiltersAndSearchWrapper>
  );
};

export default React.memo(CalendarFiltersAndSearch);
