import {
  IProvider,
  ISmartResultCard,
  ISmartScheduleOpenings,
  TimeSlot,
  HydratedTimeSlot
} from 'model/v2';
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { RecurringSSFilterInputWrapper } from '../Style';
import { Grid, Input, InputOnChangeData, Radio } from 'semantic-ui-react';
import { Datepicker } from 'api/sharedComponents/reactHookFormComponents';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckListWrapper } from 'components/calendar/filters/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  ICalendarFilterOption,
  ICalendarResource,
  IRecurringReqData,
  IRecurringSSFilter
} from 'model/calendar/filters';
import RecurringSSFilter from 'components/calendar/filters/recurringSSFilter';
import moment, { Moment } from 'moment-timezone';
import { FilterTagListWrapper } from 'components/calendar/calendarToolbar/FilterTagList/style';
import Tag from 'components/calendar/calendarToolbar/FilterTagList/Tag';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { useRemoveProviderFromCalendar } from 'pages/MainCalendarPage/FormPhantomEvents/CustomPhantomHooks';
import { TELEHEALTH_SS_APPT_IDS } from 'utils/constants/appointmentsTypes';
export const searchValueContext = React.createContext('');

const inclinicType = { label: 'In-clinic', value: '2' };
const offsiteType = { label: 'Offsite', value: '4' };
const telehealthType = { label: 'Telehealth', value: '1' };

// modern JS actually have a native Set.prototype.intersection method, but our TS version doesn't have it. Rather than fight TS version upgrade I implemented a simple utility that does the same thing
function _shimForSetIntersections<T>(set1: Set<T>, set2: Set<T>) {
  const resultSet: Set<T> = new Set();
  const smallerSet = set1.size <= set2.size ? set1 : set2;
  const largerSet = smallerSet === set1 ? set2 : set1;
  for (const entry of smallerSet) {
    if (largerSet.has(entry)) resultSet.add(entry);
  }
  return resultSet;
}

// these two functions could also be defined with useMemo using closures for the setStates and clientAvailToggle. don't know if it matters significantly which way to pick
// there may likely be a way to encode the location/ESD information necessary for filter adjustment into the card data during "normal" processing, removing the need for these
// If performance becomes a meaningful issue that is the next step to investigate.
const _setFirstLocationsFromCards = (
  cards: ISmartResultCard[],
  setCheckedFilters: (value: React.SetStateAction<IRecurringSSFilter>) => void
) => {
  const card = cards[0];
  const newLocations: ICalendarFilterOption[] = [];
  if (card.isInClinic) newLocations.push(inclinicType);
  if (card.isOffsite) newLocations.push(offsiteType);
  if (card.isTelehealth) newLocations.push(telehealthType);

  if (newLocations.length) {
    setCheckedFilters(prev => ({
      ...prev,
      locations: newLocations
    }));
  }
};
const _setPostSearchESDFromCards = (
  cards: ISmartResultCard[],
  clientAvailabilityToggle: boolean,
  setReqData: (value: React.SetStateAction<IRecurringReqData>) => void
) => {
  let earliestRangeMatchingClientAvailFilter: TimeSlot | undefined;
  cards.forEach(card => {
    const timeRanges = card.timeRanges
      ?.map(range => {
        return clientAvailabilityToggle
          ? range.clientAvailableRanges
          : range.fullProviderRanges;
      })
      .flat();

    timeRanges?.forEach(range => {
      if (earliestRangeMatchingClientAvailFilter === undefined)
        earliestRangeMatchingClientAvailFilter = range;

      const currentRangeIsEarlier =
        new Date(range!.startTime) <
        new Date(earliestRangeMatchingClientAvailFilter!.startTime);
      if (currentRangeIsEarlier) earliestRangeMatchingClientAvailFilter = range;
    });
  });
  if (earliestRangeMatchingClientAvailFilter === undefined) {
    throw new Error('Could not find earliest range');
  }
  setReqData(prev => ({
    ...prev,
    startDate: moment(
      earliestRangeMatchingClientAvailFilter!.startTime
    ).toDate()
  }));
};

interface IProps {
  smartResults: ISmartScheduleOpenings;
  setOpeningCards: Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  reqData: IRecurringReqData;
  setReqData: Dispatch<React.SetStateAction<IRecurringReqData>>;
  setOpeningCardsStatus: Dispatch<React.SetStateAction<boolean>>;
  setSelectedCards: Dispatch<React.SetStateAction<Set<number>>>;
  smartLoading: boolean;
  isAba: boolean;
  clientTimezone: string;
  openingCards: ISmartResultCard[];
  filteredSpecialities: ICalendarFilterOption[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
}
const RecurringSSFilterInput: React.FC<IProps> = ({
  smartResults,
  setOpeningCards,
  reqData,
  setReqData,
  setOpeningCardsStatus,
  setSelectedCards,
  smartLoading,
  isAba,
  clientTimezone,
  openingCards,
  filteredSpecialities,
  setPaginationArray
}: IProps) => {
  let defaultSelectedLoc = [inclinicType];
  if (isAba) defaultSelectedLoc = [offsiteType, inclinicType];
  if (
    TELEHEALTH_SS_APPT_IDS[process.env.REACT_APP_STAGE!].includes(
      reqData.data?.appType!
    )
  ) {
    defaultSelectedLoc = [telehealthType];
  }
  const checkedFiltersData: IRecurringSSFilter = {
    locations: defaultSelectedLoc,
    dayOfWeeks: [],
    providers: new Set(),
    clinics: [],
    specialities: filteredSpecialities,
    transportation: []
  };
  const [checkedFilters, setCheckedFilters] = useState<IRecurringSSFilter>(
    checkedFiltersData
  );
  const methods = useFormContext();
  const [filterDropdownExpand, setFilterDropdownExpand] = useState({
    filter: false
  });
  const [searchValue, setSearchValue] = useState({
    filter: ''
  });
  const moreFiltersRef = useRef<HTMLDivElement>(null);
  const [defaultDayOfWeeks, setDefaultDayOfWeeks] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [deafultClinics, setDefaultClinics] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [deafultSpecialities, setDeafultSpecialities] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [defaultLocations, setDefaultLocations] = React.useState<
    ICalendarFilterOption[]
  >(defaultSelectedLoc);
  const [defaultTransportation, setDefaultTransportation] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [selectedProvidersList, setSelectedProvidersList] = useState(
    new Set<number>()
  );
  const [availableDayOfWeeks, setAvailableDayOfWeeks] = useState<string[]>([]);
  const [availableLocations, setAvailableLocations] = useState<string[]>([]);
  const [
    clientAvailabilityToggle,
    setClientAvailabilityToggle
  ] = React.useState<boolean>(true);
  const [adjacentToggle, setAdjacentToggle] = React.useState<boolean>(!isAba);
  const [
    shouldPerformAutoFilterAdjustment,
    setShouldPerformAutoFilterAdjustment
  ] = useState(true);
  const filterRefs = {
    filter: moreFiltersRef
  };
  // Use a ref to track the previous value of filteredSpecialities
  const prevFilteredSpecialities = useRef<ICalendarFilterOption[]>(
    filteredSpecialities
  );
  // Effect that only updates checkedFilters.specialities if filteredSpecialities has changed
  useEffect(() => {
    if (
      JSON.stringify(prevFilteredSpecialities.current) !==
      JSON.stringify(filteredSpecialities)
    ) {
      // Update the checkedFilters specialities if filteredSpecialities has changed
      setCheckedFilters(prev => ({
        ...prev,
        specialities: filteredSpecialities
      }));
      // Update the ref to the latest filteredSpecialities
      prevFilteredSpecialities.current = filteredSpecialities;
    }
  }, [filteredSpecialities]);
  const onClientAvailabilityToggle = useCallback(() => {
    setClientAvailabilityToggle(prev => !prev);
  }, [setClientAvailabilityToggle]);
  const onAdjacentToggle = useCallback(() => {
    setAdjacentToggle(prev => !prev);
  }, [setAdjacentToggle]);
  const startDateHandler = useCallback(
    (val: Moment) => {
      setReqData(prev => ({
        ...prev,
        startDate: val.toDate()
      }));
    },
    [setReqData]
  );
  const addWeeksToDate = useCallback(
    (hydratedTimeSlot: HydratedTimeSlot) => {
      let weeks = moment(reqData.startDate as Date).diff(
        moment(hydratedTimeSlot.effectiveStartDate),
        'weeks'
      );
      if (weeks < 0) return hydratedTimeSlot;
      let effectiveStartDate = moment(hydratedTimeSlot.effectiveStartDate).add(
        weeks,
        'weeks'
      );
      while (
        moment(moment(reqData.startDate as Date).format('YYYY-MM-DD')).isAfter(
          moment(moment(effectiveStartDate).format('YYYY-MM-DD'))
        )
      ) {
        effectiveStartDate = moment(effectiveStartDate).add(1, 'weeks');
      }
      const originalStartTime = moment(hydratedTimeSlot.timeSlot.startTime);
      const originalEndTime = moment(hydratedTimeSlot.timeSlot.endTime);
      const newDate = moment(effectiveStartDate);
      hydratedTimeSlot.timeSlot.startTime = newDate
        .set({
          hour: originalStartTime.hours(),
          minute: originalStartTime.minutes(),
          second: originalStartTime.seconds(),
          millisecond: originalStartTime.milliseconds()
        })
        .toISOString();
      hydratedTimeSlot.timeSlot.endTime = newDate
        .set({
          hour: originalEndTime.hours(),
          minute: originalEndTime.minutes(),
          second: originalEndTime.seconds(),
          millisecond: originalEndTime.milliseconds()
        })
        .toISOString();
      return hydratedTimeSlot;
    },
    [reqData]
  );
  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();
  const AGGREGATION_TIMESLOT_FORMAT = 'HH:mm';

  // this is the main logic that processes cards for purposes of filtering.
  // filters fall into two categories:
  // auto-adjust filters, which have some sort of "opinionated" default value that we will update if no cards are visible through our filters (e.g. reqData.startDate, clientAvail, location)
  // and "unopinionated" filters, which are effectively "select all options" initially, such that the filter will never exclude cards until a user interacts with it (e.g. clinic, specialty, reliable transport)
  useEffect(() => {
    setPaginationArray([]);
    removeAllProvidersFromCalendar();
    let openingCards: ISmartResultCard[] = JSON.parse(
      JSON.stringify(smartResults.openingCards)
    );
    // determine Available Locations and Days for enabling/disabling filter checkboxes/etc.
    const availableDayOfWeeksArr: string[] = [];
    const availableLocationsArr: string[] = [];
    openingCards.forEach(card => {
      const dayOfWeek = card.dayOfWeek.toString();
      if (!availableDayOfWeeksArr.includes(dayOfWeek))
        availableDayOfWeeksArr.push(dayOfWeek);
      const locationValue = card.isInClinic
        ? inclinicType.value
        : card.isOffsite
        ? offsiteType.value
        : card.isTelehealth
        ? telehealthType.value
        : '0';
      if (!availableLocationsArr.includes(locationValue))
        availableLocationsArr.push(locationValue);
    });

    setAvailableDayOfWeeks(availableDayOfWeeksArr);
    setAvailableLocations(availableLocationsArr);

    // parse cards to determine what passes various filter combos, update filters on render if necessary
    const cardsMatchingLocationFilter: Set<ISmartResultCard> = new Set();
    const cardsMatchingClientAvailFilter: Set<ISmartResultCard> = new Set();
    const cardsMatchingDateFilter: Set<ISmartResultCard> = new Set();
    const cardsMatchingNoFilter: Set<ISmartResultCard> = new Set(openingCards); // we start the set with all cards, and will remove when added to other sets

    const postSearchDate = moment(reqData.startDate as Date).startOf('D');
    // in order to get an accurate count for which cards match on location, we must check all cards before we filter out those with timeslots.length = 0 (for example if the card's range starts 4 weeks in the future outside of our date window)
    // location, Day Of week, clincs, provider, speciality, transportation filters  moved to top
    openingCards.forEach(card => {
      // Location type filter
      const locationsFilter = checkedFilters.locations.map(item => item.value);
      const passesLocationFilter = checkedFilters.locations.length
        ? card.isTelehealth
          ? locationsFilter.includes('1')
          : card.isInClinic
          ? locationsFilter.includes('2')
          : card.isOffsite
          ? locationsFilter.includes('4')
          : false
        : true;
      if (passesLocationFilter) {
        cardsMatchingLocationFilter.add(card);
        cardsMatchingNoFilter.delete(card);
      }
    });

    openingCards = openingCards.filter(card => {
      // while we calculate cardsMatchingLocationFilter above, we must actually apply the filter still
      const passesLocationFilter = cardsMatchingLocationFilter.has(card);

      // Day of weeks filter
      const dayOfWeekFilter = checkedFilters.dayOfWeeks.map(item => item.value);
      const passesDayOfWeekFilter =
        checkedFilters.dayOfWeeks.length > 0
          ? dayOfWeekFilter.includes(card.dayOfWeek.toString())
          : true;

      // Clinics filter
      const clinicsFilter = checkedFilters.clinics.map(item => item.value);
      const passesClinicsFilter =
        checkedFilters.clinics.length > 0
          ? clinicsFilter.includes(card.clinic?.id!.toString()!) &&
            card.isInClinic
          : true;

      // Transportation filter
      const transportationFilter = checkedFilters.transportation?.map(
        item => item.value
      );
      const passesTransportationFilter =
        checkedFilters.transportation?.length! > 0
          ? transportationFilter?.includes('1') &&
            card.isOffsite &&
            card.provider.transportation
          : true;

      // Providers filter
      const providerFilterIds = Array.from(checkedFilters.providers);
      const passesProviderFilter =
        providerFilterIds.length > 0
          ? providerFilterIds.includes(card.provider.id!)
          : true;

      // Speciality filter
      const specialitiesFilter = checkedFilters.specialities.map(
        item => item.value
      );
      const passesSpecialityFilter =
        checkedFilters.specialities.length > 0
          ? specialitiesFilter.includes(
              card.provider?.speciality?.abbreviation!.toString()!
            )
          : true;

      if (
        !passesLocationFilter ||
        !passesDayOfWeekFilter ||
        !passesClinicsFilter ||
        !passesProviderFilter ||
        !passesSpecialityFilter ||
        !passesTransportationFilter
      )
        return false;
      return true;
    });

    if (!openingCards.length) {
      setOpeningCardsStatus(false);
      setShouldPerformAutoFilterAdjustment(false);
      return;
    }

    // Date, Client Availbility filter
    openingCards.forEach(card => {
      let historicalRanges: TimeSlot[] = [];
      const currentWeekRanges: TimeSlot[] = [];
      const futureRanges: TimeSlot[] = [];

      // identify all of the potentially relevant timeSlots for the selected clientAvailability filter and postSearchDate
      card.timeRanges?.forEach(rangesByESD => {
        const rangeESD = moment.tz(
          rangesByESD.effectiveStartDate,
          clientTimezone
        );

        if (rangesByESD.clientAvailableRanges.length) {
          cardsMatchingClientAvailFilter.add(card); // duplicate .add into a Set on subsequent iterations ignored for a set
          cardsMatchingNoFilter.delete(card);
        }

        // decide if we're looking for fullProvider ranges of clientAvailable ranges
        const rangesToConsider = clientAvailabilityToggle
          ? rangesByESD.clientAvailableRanges
          : rangesByESD.fullProviderRanges;

        if (rangeESD.isBefore(postSearchDate)) {
          historicalRanges.push(...rangesToConsider);
          cardsMatchingDateFilter.add(card); // duplicate .add will be ignored for a set
          cardsMatchingNoFilter.delete(card);
        } else if (
          rangeESD.isBetween(
            postSearchDate,
            postSearchDate.clone().add(1, 'w'),
            undefined,
            '[)'
          )
        ) {
          currentWeekRanges.push(...rangesToConsider);
          cardsMatchingDateFilter.add(card); // duplicate .add will be ignored for a set
          cardsMatchingNoFilter.delete(card);
        } else if (
          rangeESD.isBetween(
            postSearchDate.clone().add(1, 'w'),
            postSearchDate.clone().add(3, 'w'),
            undefined,
            '[)'
          )
        ) {
          futureRanges.push(...rangesToConsider);
          cardsMatchingDateFilter.add(card); // duplicate .add will be ignored for a set
          cardsMatchingNoFilter.delete(card);
        }
      });

      // compare historical timeSlots to those in the "current" week to find historical islands
      historicalRanges = historicalRanges.filter(historicalRange => {
        const historicalRangeHHMMSSStart = moment
          .tz(historicalRange.startTime, clientTimezone)
          .format(AGGREGATION_TIMESLOT_FORMAT);
        const historicalRangeHHMMSSEnd = moment
          .tz(historicalRange.endTime, clientTimezone)
          .format(AGGREGATION_TIMESLOT_FORMAT);

        const historicalIsIslandForAllCurrent = currentWeekRanges.every(
          currentWeekRange => {
            const currentWeekHHMMSSStart = moment
              .tz(currentWeekRange.startTime, clientTimezone)
              .format(AGGREGATION_TIMESLOT_FORMAT);
            const currentWeekHHMMSSEnd = moment
              .tz(currentWeekRange.endTime, clientTimezone)
              .format(AGGREGATION_TIMESLOT_FORMAT);

            const historicalIsBeforeCurrent =
              historicalRangeHHMMSSStart < currentWeekHHMMSSStart &&
              historicalRangeHHMMSSEnd <= currentWeekHHMMSSEnd;

            const historicalIsAfterCurrent =
              historicalRangeHHMMSSStart >= currentWeekHHMMSSStart &&
              historicalRangeHHMMSSEnd > currentWeekHHMMSSEnd;

            return historicalIsBeforeCurrent || historicalIsAfterCurrent;
          }
        );

        return historicalIsIslandForAllCurrent;
      });

      // modify in place to ensure card reference equality
      card.timeSlots = [
        ...historicalRanges,
        ...currentWeekRanges,
        ...futureRanges
      ].map(range => ({
        timeSlot: {
          startTime: range.startTime,
          endTime: range.endTime,
          adjacentType: 0
        },
        clientFullyAvailable: clientAvailabilityToggle, // timeslots is based on rangesToConsider which dynamically depends on clientAvailabilityToggle. this step might generate a card tagged "clientFullyAvailable" with empty timeslots (because it doesn't actually have any clientFullyAvail ranges), but there is a filter to remove such cards meaning that only those that actually have clientFullyAvailable slots will be shown
        effectiveStartDate: moment
          .tz(range.startTime, clientTimezone)
          .format('YYYY-MM-DD HH:mm:ss'),
        previousAdjacentEndDate: new Date(), // our logic is completely ignoring this field now
        trailingAdjacentEndDate: new Date() // our logic is completely ignoring this field now
      }));
    });
    // For any timeslots that are historical, bump up their startDates to the current week
    openingCards.forEach(resultCard => {
      resultCard.timeSlots!.forEach(hydratedTimeSlot => {
        hydratedTimeSlot = addWeeksToDate(hydratedTimeSlot);
      });
    });
    // Ensure there are no cards with 0 timeslots
    if (reqData.startDate !== undefined) {
      openingCards = openingCards.filter(card => card.timeSlots!.length > 0);
    }
    openingCards.forEach(card => {
      card.startTime = card?.timeSlots?.[0]?.timeSlot?.startTime ?? ''; // we filter out cards with no timeslots (which are populated for historical < N < N+2 week ranges) later, but we process in this order to enable filter auto-modification
      card.endTime = card?.timeSlots?.[0]?.timeSlot?.endTime ?? ''; // we filter out cards with no timeslots (which are populated for historical < N < N+2 week ranges) later, but we process in this order to enable filter auto-modification
      card.startDate =
        moment(card?.timeSlots?.[0]?.timeSlot?.startTime).format(
          'YYYY-MM-DD'
        ) ?? ''; // we filter out cards with no timeslots (which are populated for historical < N < N+2 week ranges) later, but we process in this order to enable filter auto-modification
    });
    const filteredOpeningCards = openingCards.sort((a, b) => {
      return moment(a.startTime).valueOf() - moment(b.startTime).valueOf();
    });

    setSelectedCards(new Set<number>());

    if (filteredOpeningCards.length > 0) {
      // T-T-T case, see comment below
      setOpeningCardsStatus(true);

      // we found results matching our filters, so setState to ensure we don't try again.
      if (shouldPerformAutoFilterAdjustment)
        setShouldPerformAutoFilterAdjustment(false);
    } else {
      // only true on the first pass processing cards
      if (shouldPerformAutoFilterAdjustment) {
        // store the intersections used for repeated reference
        const cardsMatchingLocationAndClientAvailFilters = _shimForSetIntersections(
          cardsMatchingLocationFilter,
          cardsMatchingClientAvailFilter
        );
        const cardsMatchingClientAvailAndDateFilters = _shimForSetIntersections(
          cardsMatchingClientAvailFilter,
          cardsMatchingDateFilter
        );

        // ***************************************
        // I will use a "3 bit" way to comment on the possible buckets a card might fall into.
        // they represent "there are cards matching the locationFilter-clientAvailFilter-2weekFilter".
        // so T-T-F is the case with results matching our location and client avail filters, but those matches don't also pass the 2 week filter
        // 2^3 = 8, but the T-T-T case is handled where we setOpeningCardsStatus(true), aka something passes default filters, and the F-F-F case doesn't need to be handled since the BE won't send results if nothing matches
        // this leaves us with 6 cases to explicitly handle

        // if T-T-F -> adjust ESD (and we're done)
        if (cardsMatchingLocationAndClientAvailFilters.size) {
          _setPostSearchESDFromCards(
            [...cardsMatchingLocationAndClientAvailFilters.values()],
            clientAvailabilityToggle,
            setReqData
          );
        }
        // eif T-F-T -> adjust clientAvail (and we're done)
        else if (
          _shimForSetIntersections(
            cardsMatchingLocationFilter,
            cardsMatchingDateFilter
          ).size
        ) {
          setClientAvailabilityToggle(prev => !prev);
        }
        // eif T-F-F -> adjust ESD (then we loop back and adjust clientAvail to finish) (or we do both at once..?)
        else if (cardsMatchingLocationFilter.size) {
          _setPostSearchESDFromCards(
            [...cardsMatchingLocationFilter.values()],
            !clientAvailabilityToggle,
            setReqData
          );
          setClientAvailabilityToggle(prev => !prev);
        }
        // eif F-T-T -> adjust locationType (and we're done)
        else if (cardsMatchingClientAvailAndDateFilters.size) {
          _setFirstLocationsFromCards(
            [...cardsMatchingClientAvailAndDateFilters.values()],
            setCheckedFilters
          );
        }
        // eif F-T-F -> adjust ESD (then we loop back and adjust locationType to finish) (or we do both at once..?)
        else if (cardsMatchingClientAvailFilter.size) {
          const cards = [...cardsMatchingClientAvailFilter.values()];
          _setPostSearchESDFromCards(
            cards,
            clientAvailabilityToggle,
            setReqData
          );
          _setFirstLocationsFromCards(cards, setCheckedFilters);
        }
        // eif F-F-T -> adjust clientAvail (then we loop back and adjust locationType to finish) (or we do both at once..?)
        else if (cardsMatchingDateFilter.size) {
          _setFirstLocationsFromCards(
            [...cardsMatchingDateFilter.values()],
            setCheckedFilters
          );
          setClientAvailabilityToggle(prev => !prev);
        } else if (cardsMatchingNoFilter.size) {
          // F-F-F -> adjust all 3
          const cards = [...cardsMatchingNoFilter.values()];
          _setPostSearchESDFromCards(
            cards,
            !clientAvailabilityToggle,
            setReqData
          );
          _setFirstLocationsFromCards(cards, setCheckedFilters);
          setClientAvailabilityToggle(prev => !prev);
        } else {
          throw new Error(
            'Unplanned case encountered. Logical leak in RecurringSSPostSearchRangeFilters useEffect'
          );
        }
        setShouldPerformAutoFilterAdjustment(false); // this *shouldn't* be necessary if auto-adjust is effectively getting cards to show, but protects against infinite loops should they fail to

        // ***************************************
      }
      setOpeningCardsStatus(false);
    }

    filteredOpeningCards.sort((a, b) => {
      return a.provider.name!.localeCompare(b.provider.name!);
    });

    setOpeningCards(filteredOpeningCards);
  }, [
    smartResults,
    clientAvailabilityToggle,
    adjacentToggle,
    reqData,
    checkedFilters,
    addWeeksToDate,
    clientTimezone,
    setOpeningCards,
    setOpeningCardsStatus,
    setReqData,
    setSelectedCards,
    shouldPerformAutoFilterAdjustment,
    setAvailableLocations,
    setAvailableDayOfWeeks
  ]);

  const handleGenericBlur = (e: React.FocusEvent, filterType: 'filter') => {
    const currentRef = filterRefs[filterType];

    if (
      currentRef.current &&
      (e.relatedTarget === currentRef.current ||
        currentRef.current.contains(e.relatedTarget as Node))
    ) {
      return; // Do nothing if the click was inside the dropdown
    }

    if (filterDropdownExpand[filterType]) {
      setFilterDropdownExpand(prevState => ({
        ...prevState,
        [filterType]: false
      }));
    }
  };
  const toggleSpecialtyList = () => {
    if (smartLoading) return;
    setDefaultLocations(checkedFilters.locations);
    setDefaultDayOfWeeks(checkedFilters.dayOfWeeks);
    setSelectedProvidersList(checkedFilters.providers);
    setDefaultClinics(checkedFilters.clinics);
    setDeafultSpecialities(checkedFilters.specialities);
    setFilterDropdownExpand({
      ...filterDropdownExpand,
      filter: !filterDropdownExpand.filter
    });
  };
  const handleGenericSearch = (
    _e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    typeOfSearch: string
  ) => {
    const isSupportedSearchType = ['filter'].includes(typeOfSearch);

    if (isSupportedSearchType) {
      setFilterDropdownExpand(prevState => ({
        ...prevState,
        [typeOfSearch]: true
      }));

      setSearchValue(prevState => ({
        ...prevState,
        [typeOfSearch]: data?.value
      }));
    }
  };
  //Base handleSearchedValues method
  const createHandleSearchedValues = useCallback(
    (type: 'filter') => {
      return (list: ICalendarFilterOption[]) => {
        const searchTerm = searchValue[type];
        return searchTerm === ''
          ? list
          : list?.filter(option => {
              return option.label
                ?.toLowerCase()
                .startsWith(searchTerm?.toLowerCase());
            });
      };
    },
    [searchValue]
  );
  const handleSpecialtySearchedValues = createHandleSearchedValues('filter');
  const handleRemoveTag = useCallback(
    (type: CALENDAR_FILTER_ENUM, value: string) => {
      if (smartLoading) return;
      if (type === CALENDAR_FILTER_ENUM.locations) {
        setCheckedFilters(prev => ({
          ...prev,
          locations: prev.locations.filter(location => location.value !== value)
        }));
        setDefaultLocations(prev =>
          prev.filter(location => location.value !== value)
        );
      }
      if (type === CALENDAR_FILTER_ENUM.dayOfWeeks) {
        setCheckedFilters(prev => ({
          ...prev,
          dayOfWeeks: prev.dayOfWeeks.filter(day => day.value !== value)
        }));
        setDefaultDayOfWeeks(prev => prev.filter(day => day.value !== value));
      }
      if (type === CALENDAR_FILTER_ENUM.clinics) {
        setCheckedFilters(prev => ({
          ...prev,
          clinics: prev.clinics.filter(day => day.value !== value)
        }));
        setDefaultClinics(prev => prev.filter(day => day.value !== value));
      }
      if (type === CALENDAR_FILTER_ENUM.transportation) {
        setCheckedFilters(prev => ({
          ...prev,
          transportation: prev.transportation?.filter(
            data => data.value !== value
          )
        }));
        if (setDefaultTransportation)
          setDefaultTransportation(prev =>
            prev.filter(day => day.value !== value)
          );
      }
      if (type === CALENDAR_FILTER_ENUM.specialities) {
        setCheckedFilters(prev => ({
          ...prev,
          specialities: prev.specialities.filter(day => day.value !== value)
        }));
        setDeafultSpecialities(prev => prev.filter(day => day.value !== value));
      }
      if (type === CALENDAR_FILTER_ENUM.departments) {
        setSelectedProvidersList(prev => {
          const newSet = new Set(prev);
          newSet.delete(parseInt(value));
          return newSet;
        });
        setCheckedFilters(prev => {
          const newProvidersSet = new Set(prev.providers);
          newProvidersSet.delete(parseInt(value));

          return {
            ...prev,
            providers: newProvidersSet
          };
        });
      }
    },
    [smartLoading]
  );
  const providersData = smartResults.openingCards?.map(
    result => result.provider
  );
  const filteredProviders: IProvider[] = Array.from(
    new Set(providersData?.map(provider => provider?.id))
  )?.map(
    providerId =>
      providersData?.find(provider => provider?.id === providerId) as IProvider
  );
  const providersObject = filteredProviders.reduce((acc, provider) => {
    acc[provider.id!] = provider;
    return acc;
  }, {} as Record<number, IProvider>);
  const disabledDate = (current: Date) => {
    return (
      current &&
      moment(moment(current).format('YYYY-MM-DD')).isBefore(
        moment(moment(reqData.data?.startDate as Date).format('YYYY-MM-DD'))
      )
    );
  };
  return (
    <RecurringSSFilterInputWrapper>
      <Grid className="grid-cls">
        <Grid.Row style={{ paddingTop: '0px' }}>
          <Grid.Column width={10} className="toggle-cls">
            <div className="toggle-rectangle">
              <div>
                <div className="label-title "> Within Client Availability</div>
              </div>
              <Radio
                toggle
                onChange={onClientAvailabilityToggle}
                disabled={smartLoading}
                checked={clientAvailabilityToggle}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={5} className="toggle-cls">
            <div className="toggle-rectangle">
              <div>
                <div className="label-title "> Adjacent</div>
              </div>
              <Radio
                toggle
                onChange={onAdjacentToggle}
                defaultChecked={!isAba}
                disabled={smartLoading || isAba}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className="grid-cls">
        <Grid.Row style={{ paddingBottom: '0px', paddingTop: '0px' }}>
          <Grid.Column width={7}>
            <Controller
              name="startDate"
              control={methods.control}
              render={fieldProps => {
                return (
                  <Datepicker
                    className="datapicker-style"
                    name="selectedStartDate"
                    placeholder="Select Start Date"
                    format="MM/DD/YYYY"
                    field={fieldProps}
                    disabled={smartLoading}
                    defaultValue={
                      reqData.startDate ? moment(reqData.startDate as Date) : ''
                    }
                    onChange={startDateHandler}
                    errors={methods.errors}
                    allowClear={false}
                    disabledDate={disabledDate}
                  />
                );
              }}
            />
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={8}>
            <CheckListWrapper
              visible={filterDropdownExpand.filter}
              height="300px"
              style={{ position: 'relative', marginTop: '0px' }}
            >
              <div
                ref={moreFiltersRef}
                onBlur={e => handleGenericBlur(e, 'filter')}
                tabIndex={-1}
              >
                <div
                  className="filter-input_wrapper"
                  onClick={toggleSpecialtyList}
                >
                  <Input
                    className="filter_input"
                    placeholder="More Filters"
                    onChange={event =>
                      handleGenericSearch(
                        event,
                        { value: event.currentTarget.value },
                        'filter'
                      )
                    }
                  />
                  {!filterDropdownExpand.filter && (
                    <FontAwesomeIcon className="icon" icon={faCaretDown} />
                  )}
                  {filterDropdownExpand.filter && (
                    <FontAwesomeIcon
                      className="icon"
                      icon={faCaretDown}
                      style={{ opacity: 0 }}
                    />
                  )}
                </div>

                {filterDropdownExpand.filter && (
                  <>
                    <searchValueContext.Provider value={searchValue.filter}>
                      <div className="list_wrapper filter_list">
                        <RecurringSSFilter
                          handleSearchedValues={handleSpecialtySearchedValues}
                          setDefaultDayOfWeeks={setDefaultDayOfWeeks}
                          defaultDayOfWeeks={defaultDayOfWeeks}
                          setDefaultLocations={setDefaultLocations}
                          defaultLocations={defaultLocations}
                          setFilterDropdownExpand={setFilterDropdownExpand}
                          smartResultsData={smartResults.openingCards}
                          selectedProvidersList={selectedProvidersList}
                          setSelectedProvidersList={setSelectedProvidersList}
                          setCheckedFilters={setCheckedFilters}
                          checkedFilters={checkedFilters}
                          setDefaultClinics={setDefaultClinics}
                          deafultClinics={deafultClinics}
                          setDeafultSpecialities={setDeafultSpecialities}
                          deafultSpecialities={deafultSpecialities}
                          availableDayOfWeeks={availableDayOfWeeks}
                          availableLocations={availableLocations}
                          setDefaultTransportation={setDefaultTransportation}
                          defaultTransportation={defaultTransportation}
                          isAba={isAba}
                        ></RecurringSSFilter>
                      </div>
                    </searchValueContext.Provider>
                  </>
                )}
              </div>
            </CheckListWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <FilterTagListWrapper
        style={{ marginBottom: '0px', marginLeft: '0px', marginRight: '0px' }}
      >
        <div className="tags-container pt-10" style={{ width: '100%' }}>
          {Array.from(checkedFilters.providers).length > 0 &&
            Array.from(checkedFilters.providers).map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.departments}
                  label={providersObject[f].name!}
                  handleRemoveTag={handleRemoveTag}
                  value={f.toString()}
                />
              );
            })}
          {checkedFilters.locations.length > 0 &&
            checkedFilters.locations.map(f => {
              const locationTypeCount =
                f.value === inclinicType.value
                  ? openingCards.filter(card => card.isInClinic).length
                  : f.value === offsiteType.value
                  ? openingCards.filter(card => card.isOffsite).length
                  : openingCards.filter(card => card.isTelehealth).length;
              const labelName = `${f.label} (${locationTypeCount})`;
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.locations}
                  label={labelName}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.dayOfWeeks.length > 0 &&
            checkedFilters.dayOfWeeks.map(f => {
              const dayLabel = f.label + 's';
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.dayOfWeeks}
                  label={dayLabel}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.clinics.length > 0 &&
            checkedFilters.clinics.map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.clinics}
                  label={f.label}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.transportation?.length! > 0 &&
            checkedFilters.transportation?.map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.transportation}
                  label={f.label}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.specialities.length > 0 &&
            checkedFilters.specialities.map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.specialities}
                  label={f.label}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
        </div>
      </FilterTagListWrapper>
      {checkedFilters.transportation?.map(item => item.value).includes('1') && (
        <>
          <Grid>
            <Grid.Row style={{ paddingBottom: '0px' }}>
              <Grid.Column width={16}>
                <p>
                  <strong>Note:</strong> Providers without reliable
                  transportation are excluded from offsite services.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </RecurringSSFilterInputWrapper>
  );
};
export default React.memo(RecurringSSFilterInput);
