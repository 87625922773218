import gql from 'graphql-tag';

export const EDIT_CARE_PLAN = gql`
  mutation($clientID: ID!, $carePlan: CarePlanInput!) {
    updateCarePlan(clientID: $clientID, carePlan: $carePlan) {
      statusCode
    }
  }
`;

export const ADD_AXON_CARE_PLAN_NOTES = gql`
  mutation($note: AxonCarePlanNotesInput!) {
    addAxonCarePlanNotes(data: $note) {
      id
      note
      actionBy
      actionAt
      createdAt
    }
  }
`;

export const UPDATE_CARE_PLAN = gql`
  mutation($data: CarePlanSalesForceUpdateInput!) {
    updateCarePlan(data: $data)
  }
`;

export const CREATE_SALESFORCE_CASE_FROM_CAREPLAN = gql`
  mutation createSalesforceCaseFromCareplan(
    $patientName: String!
    $clientClinicName: String!
    $carePlanType: String!
    $carePlanName: String!
    $waitlistStatus: String!
    $waitlistReason: String!
    $smartSchedulingResult: String!
    $clientSalesforceId: String!
  ) {
    createSFCaseFromCareplan(
      patientName: $patientName
      clientClinicName: $clientClinicName
      carePlanType: $carePlanType
      carePlanName: $carePlanName
      waitlistStatus: $waitlistStatus
      waitlistReason: $waitlistReason
      smartSchedulingResult: $smartSchedulingResult
      clientSalesforceId: $clientSalesforceId
    )
  }
`;
