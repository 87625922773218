import {
  HydratedTimeSlot,
  IProvider,
  ISmartResultCard,
  ISmartScheduleOpenings
} from 'model/v2';
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { RecurringSSFilterInputWrapper } from './Style';
import { Grid, Input, InputOnChangeData, Radio } from 'semantic-ui-react';
import { Datepicker } from 'api/sharedComponents/reactHookFormComponents';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckListWrapper } from 'components/calendar/filters/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  ICalendarFilterOption,
  ICalendarResource,
  IRecurringReqData,
  IRecurringSSFilter
} from 'model/calendar/filters';
import RecurringSSFilter from 'components/calendar/filters/recurringSSFilter';
import moment, { Moment } from 'moment';
import { FilterTagListWrapper } from 'components/calendar/calendarToolbar/FilterTagList/style';
import Tag from 'components/calendar/calendarToolbar/FilterTagList/Tag';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { useRemoveProviderFromCalendar } from 'pages/MainCalendarPage/FormPhantomEvents/CustomPhantomHooks';
import { TELEHEALTH_SS_APPT_IDS } from 'utils/constants/appointmentsTypes';
export const searchValueContext = React.createContext('');

interface IProps {
  smartResults: ISmartScheduleOpenings;
  setOpeningCards: Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  reqData: IRecurringReqData;
  setReqData: Dispatch<React.SetStateAction<IRecurringReqData>>;
  setOpeningCardsStatus: Dispatch<React.SetStateAction<boolean>>;
  setSelectedCards: Dispatch<React.SetStateAction<Set<number>>>;
  smartLoading: boolean;
  isAba: boolean;
  openingCards: ISmartResultCard[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
}
const inclinicType = { label: 'In-clinic', value: '2' };
const offsiteType = { label: 'Offsite', value: '4' };
const telehealthType = { label: 'Telehealth', value: '1' };
const RecurringSSFilterInput: React.FC<IProps> = ({
  smartResults,
  setOpeningCards,
  reqData,
  setReqData,
  setOpeningCardsStatus,
  setSelectedCards,
  smartLoading,
  isAba,
  openingCards,
  setPaginationArray
}: IProps) => {
  let defaultSelectedLoc = [inclinicType];
  if (
    TELEHEALTH_SS_APPT_IDS[process.env.REACT_APP_STAGE!].includes(
      reqData.data?.appType!
    )
  ) {
    defaultSelectedLoc = [telehealthType];
  }
  const checkedFiltersData: IRecurringSSFilter = {
    locations: defaultSelectedLoc,
    dayOfWeeks: [],
    providers: new Set(),
    clinics: [],
    specialities: []
  };
  const [checkedFilters, setCheckedFilters] = useState<IRecurringSSFilter>(
    checkedFiltersData
  );
  const methods = useFormContext();
  const [filterDropdownExpand, setFilterDropdownExpand] = useState({
    filter: false
  });
  const [searchValue, setSearchValue] = useState({
    filter: ''
  });
  const moreFiltersRef = useRef<HTMLDivElement>(null);
  const [defaultDayOfWeeks, setDefaultDayOfWeeks] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [deafultClinics, setDefaultClinics] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [deafultSpecialities, setDeafultSpecialities] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [defaultLocations, setDefaultLocations] = React.useState<
    ICalendarFilterOption[]
  >(defaultSelectedLoc);
  const [selectedProvidersList, setSelectedProvidersList] = useState(
    new Set<number>()
  );
  const [availableDayOfWeeks, setAvailableDayOfWeeks] = useState<string[]>([]);
  const [availableLocations, setAvailableLocations] = useState<string[]>([]);
  const [
    clientAvailabilityToggle,
    setClientAvailabilityToggle
  ] = React.useState<boolean>(true);
  const [adjacentToggle, setAdjacentToggle] = React.useState<boolean>(!isAba);
  const [
    shouldPerformAutoFilterAdjustment,
    setShouldPerformAutoFilterAdjustment
  ] = useState(true);
  const filterRefs = {
    filter: moreFiltersRef
  };
  const onClientAvailabilityToggle = useCallback(() => {
    setClientAvailabilityToggle(prev => !prev);
  }, [setClientAvailabilityToggle]);
  const onAdjacentToggle = useCallback(() => {
    setAdjacentToggle(prev => !prev);
  }, [setAdjacentToggle]);
  const startDateHandler = useCallback(
    (val: Moment) => {
      setReqData(prev => ({
        ...prev,
        startDate: val.toDate()
      }));
    },
    [setReqData]
  );
  const addWeeksToDate = useCallback(
    (hydratedTimeSlot: HydratedTimeSlot) => {
      let weeks = moment(reqData.startDate).diff(
        moment(hydratedTimeSlot.effectiveStartDate),
        'weeks'
      );
      if (weeks < 0) return hydratedTimeSlot;
      let effectiveStartDate = moment(hydratedTimeSlot.effectiveStartDate).add(
        weeks,
        'weeks'
      );
      while (
        moment(moment(reqData.startDate).format('YYYY-MM-DD')).isAfter(
          moment(moment(effectiveStartDate).format('YYYY-MM-DD'))
        )
      ) {
        effectiveStartDate = moment(effectiveStartDate).add(1, 'weeks');
      }
      const originalStartTime = moment(hydratedTimeSlot.timeSlot.startTime);
      const originalEndTime = moment(hydratedTimeSlot.timeSlot.endTime);
      const newDate = moment(effectiveStartDate);
      hydratedTimeSlot.timeSlot.startTime = newDate
        .set({
          hour: originalStartTime.hours(),
          minute: originalStartTime.minutes(),
          second: originalStartTime.seconds(),
          millisecond: originalStartTime.milliseconds()
        })
        .toISOString();
      hydratedTimeSlot.timeSlot.endTime = newDate
        .set({
          hour: originalEndTime.hours(),
          minute: originalEndTime.minutes(),
          second: originalEndTime.seconds(),
          millisecond: originalEndTime.milliseconds()
        })
        .toISOString();
      return hydratedTimeSlot;
    },
    [reqData]
  );
  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();
  const getFilteredData = (
    filteredAndSortedData: ISmartResultCard[],
    reqData: IRecurringReqData
  ) => {
    filteredAndSortedData = filteredAndSortedData
      .map(card => {
        const filteredTimeSlots = card.timeSlots!.filter(
          slot =>
            moment(slot.timeSlot.startTime).toDate() >
            moment(reqData.startDate)
              .add(2, 'weeks')
              .toDate()
        );

        filteredTimeSlots.sort((a, b) => {
          const startTimeA = new Date(a.timeSlot.startTime);
          const startTimeB = new Date(b.timeSlot.startTime);
          return startTimeA.getTime() - startTimeB.getTime();
        });
        return {
          ...card,
          timeSlots: filteredTimeSlots
        };
      })
      .filter(card => card.timeSlots.length > 0)
      .sort((cardA, cardB) => {
        const earliestStartTimeA = new Date(
          cardA.timeSlots[0]?.timeSlot.startTime
        );
        const earliestStartTimeB = new Date(
          cardB.timeSlots[0]?.timeSlot.startTime
        );
        return earliestStartTimeA.getTime() - earliestStartTimeB.getTime();
      });
    return filteredAndSortedData;
  };
  const getLocationResults = (
    clientAvailabilityResults: ISmartResultCard[],
    locationsArray: string[]
  ) => {
    return clientAvailabilityResults.filter(card =>
      card.isTelehealth
        ? locationsArray.includes('1')
        : card.isInClinic
        ? locationsArray.includes('2')
        : card.isOffsite
        ? locationsArray.includes('4')
        : false
    );
  };
  useEffect(() => {
    setPaginationArray([]);
    removeAllProvidersFromCalendar();
    const openingCards: ISmartResultCard[] = JSON.parse(
      JSON.stringify(smartResults.openingCards)
    );
    // Get Available Locations and DayOfWeeks
    const availableDayOfWeeksArr: string[] = [];
    const availableLocationsArr: string[] = [];
    openingCards.forEach((card: ISmartResultCard) => {
      const dayOfWeek = card.dayOfWeek.toString();
      if (!availableDayOfWeeksArr.includes(dayOfWeek))
        availableDayOfWeeksArr.push(dayOfWeek);
      const locationValue = card.isInClinic
        ? inclinicType.value
        : card.isOffsite
        ? offsiteType.value
        : card.isTelehealth
        ? telehealthType.value
        : '0';
      if (!availableLocationsArr.includes(locationValue))
        availableLocationsArr.push(locationValue);
    });
    setAvailableDayOfWeeks(availableDayOfWeeksArr);
    setAvailableLocations(availableLocationsArr);
    let filteredOpeningCards = openingCards;
    let clientAvailabilityResults: ISmartResultCard[] = [];
    let adjacentLocationResults: ISmartResultCard[] = [];
    let defaultLocationResults: ISmartResultCard[] = [];
    let clientFullyAvailableResults: ISmartResultCard[] = [];
    if (openingCards.length === 0) setShouldPerformAutoFilterAdjustment(false);
    // Check Effective starte with start date
    filteredOpeningCards.forEach(resultCard => {
      resultCard.timeSlots!.forEach(hydratedTimeSlot => {
        hydratedTimeSlot = addWeeksToDate(hydratedTimeSlot);
      });
    });
    clientFullyAvailableResults = filteredOpeningCards;
    if (clientAvailabilityToggle) {
      clientFullyAvailableResults = filteredOpeningCards
        .map(card => ({
          ...card,
          timeSlots: card.timeSlots!.filter(
            (slot: HydratedTimeSlot) =>
              slot.clientFullyAvailable === clientAvailabilityToggle
          )
        }))
        .filter(card => card.timeSlots.length > 0);
    }
    //Startdate and 2 weeks filter
    if (reqData.startDate !== undefined) {
      filteredOpeningCards = filteredOpeningCards
        .map(card => ({
          ...card,
          timeSlots: card.timeSlots!.filter(
            (slot: HydratedTimeSlot) =>
              moment(
                moment(slot.timeSlot.startTime).format('YYYY-MM-DD')
              ).isSameOrAfter(
                moment(moment(reqData.startDate).format('YYYY-MM-DD'))
              ) &&
              moment(
                moment(slot.timeSlot.startTime).format('YYYY-MM-DD')
              ).isSameOrBefore(
                moment(
                  moment(reqData.startDate)
                    .add(2, 'weeks')
                    .format('YYYY-MM-DD')
                )
              )
          )
        }))
        .filter(card => card.timeSlots.length > 0);
    }
    // checking clinet availability filter
    if (clientAvailabilityToggle) {
      filteredOpeningCards = filteredOpeningCards
        .map(card => ({
          ...card,
          timeSlots: card.timeSlots!.filter((slot: HydratedTimeSlot) =>
            clientAvailabilityToggle
              ? slot.clientFullyAvailable === clientAvailabilityToggle
              : true
          )
        }))
        .filter(card => card.timeSlots.length > 0);
    }
    clientAvailabilityResults = filteredOpeningCards;
    // checking adjacent filter
    if (adjacentToggle) {
      filteredOpeningCards = filteredOpeningCards
        .map(card => ({
          ...card,
          timeSlots: card.timeSlots!.filter((slot: HydratedTimeSlot) => {
            const previousAdjacent: boolean = slot.previousAdjacentEndDate
              ? moment(reqData.startDate).isBefore(
                  moment(slot.previousAdjacentEndDate)
                )
              : false;
            const trailingAdjacent: boolean = slot.trailingAdjacentEndDate
              ? moment(reqData.startDate).isBefore(
                  moment(slot.trailingAdjacentEndDate)
                )
              : false;
            return adjacentToggle
              ? slot.timeSlot?.adjacentType! === 1
                ? previousAdjacent
                : slot.timeSlot?.adjacentType! === 2
                ? trailingAdjacent
                : slot.timeSlot?.adjacentType === 3
                ? trailingAdjacent || previousAdjacent
                : false
              : true;
          })
        }))
        .filter(card => card.timeSlots.length > 0);
    }
    if (checkedFilters.locations.length > 0) {
      const locationsArray = checkedFilters.locations.map(item => item.value);
      adjacentLocationResults = getLocationResults(
        clientAvailabilityResults,
        locationsArray
      );
      defaultLocationResults = getLocationResults(
        clientFullyAvailableResults,
        locationsArray
      );
      filteredOpeningCards = getLocationResults(
        filteredOpeningCards,
        locationsArray
      );
    }
    // Day of weeks filter
    if (checkedFilters.dayOfWeeks.length > 0) {
      const dayOfWeeksArray = checkedFilters.dayOfWeeks.map(item => item.value);
      filteredOpeningCards = filteredOpeningCards.filter(card =>
        dayOfWeeksArray.includes(card.dayOfWeek.toString())
      );
    }

    // Clinics filter
    if (checkedFilters.clinics.length > 0) {
      const clinicsArray = checkedFilters.clinics.map(item => item.value);
      filteredOpeningCards = filteredOpeningCards.filter(
        card =>
          clinicsArray.includes(card.clinic?.id!.toString()!) && card.isInClinic
      );
    }
    // Providers filter
    const providerIds = Array.from(checkedFilters.providers);
    if (providerIds.length > 0) {
      filteredOpeningCards = filteredOpeningCards.filter(card =>
        providerIds.includes(card.provider.id!)
      );
    }

    // Speciality filter
    if (checkedFilters.specialities.length > 0) {
      const specialitiesArray = checkedFilters.specialities.map(
        item => item.value
      );
      filteredOpeningCards = filteredOpeningCards.filter(card =>
        specialitiesArray.includes(
          card.provider?.speciality?.abbreviation!.toString()!
        )
      );
    }

    filteredOpeningCards = filteredOpeningCards.map(card => ({
      ...card,
      startTime: card.timeSlots![0].timeSlot.startTime,
      endTime: card.timeSlots![0].timeSlot.endTime,
      startDate: moment(card.timeSlots![0].timeSlot.startTime).format(
        'YYYY-MM-DD'
      )
    }));
    filteredOpeningCards = filteredOpeningCards.sort((a, b) => {
      return moment(a.startTime).valueOf() - moment(b.startTime).valueOf();
    });
    setSelectedCards(new Set<number>());
    if (filteredOpeningCards.length > 0) {
      setOpeningCardsStatus(true);
      setShouldPerformAutoFilterAdjustment(false);
    } else {
      setOpeningCardsStatus(false);
      if (shouldPerformAutoFilterAdjustment) {
        if (adjacentLocationResults.length > 0) {
          setAdjacentToggle(false);
        } else if (defaultLocationResults.length > 0) {
          const filteredAndSortedData = getFilteredData(
            defaultLocationResults,
            reqData
          );
          if (filteredAndSortedData.length > 0) {
            setReqData(prev => ({
              ...prev,
              startDate: moment(
                filteredAndSortedData[0].timeSlots![0].timeSlot.startTime
              ).toDate()
            }));
          }
        } else {
          if (!clientAvailabilityToggle) {
            const locationData = clientFullyAvailableResults;
            const inclinicResults = locationData.filter(
              card => card.isInClinic
            );
            const offsiteResults = locationData.filter(card => card.isOffsite);
            const telehealthRecords = locationData.filter(
              card => card.isTelehealth
            );
            let locationType: ICalendarFilterOption[] = [];
            if (inclinicResults.length > 0) {
              locationType = [inclinicType];
            } else if (offsiteResults.length > 0) {
              locationType = [offsiteType];
            } else if (telehealthRecords.length > 0) {
              locationType = [telehealthType];
            }
            if (locationType.length > 0) {
              setCheckedFilters(prev => ({
                ...prev,
                locations: locationType
              }));
            }
          } else setClientAvailabilityToggle(false);
        }
      }
    }
    filteredOpeningCards = filteredOpeningCards.sort((a, b) => {
      return a.provider.name!.localeCompare(b.provider.name!);
    });
    setOpeningCards(filteredOpeningCards);
  }, [
    smartResults,
    clientAvailabilityToggle,
    adjacentToggle,
    reqData,
    checkedFilters,
    addWeeksToDate,
    shouldPerformAutoFilterAdjustment,
    setShouldPerformAutoFilterAdjustment,
    setOpeningCards,
    setOpeningCardsStatus,
    setReqData,
    setSelectedCards,
    setAvailableDayOfWeeks,
    setAvailableLocations
  ]);
  const handleGenericBlur = (e: React.FocusEvent, filterType: 'filter') => {
    const currentRef = filterRefs[filterType];

    if (
      currentRef.current &&
      (e.relatedTarget === currentRef.current ||
        currentRef.current.contains(e.relatedTarget as Node))
    ) {
      return; // Do nothing if the click was inside the dropdown
    }

    if (filterDropdownExpand[filterType]) {
      setFilterDropdownExpand(prevState => ({
        ...prevState,
        [filterType]: false
      }));
    }
  };
  const toggleSpecialtyList = () => {
    if (smartLoading) return;
    setDefaultLocations(checkedFilters.locations);
    setDefaultDayOfWeeks(checkedFilters.dayOfWeeks);
    setSelectedProvidersList(checkedFilters.providers);
    setDefaultClinics(checkedFilters.clinics);
    setDeafultSpecialities(checkedFilters.specialities);
    setFilterDropdownExpand({
      ...filterDropdownExpand,
      filter: !filterDropdownExpand.filter
    });
  };
  const handleGenericSearch = (
    _e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    typeOfSearch: string
  ) => {
    const isSupportedSearchType = ['filter'].includes(typeOfSearch);

    if (isSupportedSearchType) {
      setFilterDropdownExpand(prevState => ({
        ...prevState,
        [typeOfSearch]: true
      }));

      setSearchValue(prevState => ({
        ...prevState,
        [typeOfSearch]: data?.value
      }));
    }
  };
  //Base handleSearchedValues method
  const createHandleSearchedValues = useCallback(
    (type: 'filter') => {
      return (list: ICalendarFilterOption[]) => {
        const searchTerm = searchValue[type];
        return searchTerm === ''
          ? list
          : list?.filter(option => {
              return option.label
                ?.toLowerCase()
                .startsWith(searchTerm?.toLowerCase());
            });
      };
    },
    [searchValue]
  );
  const handleSpecialtySearchedValues = createHandleSearchedValues('filter');
  const handleRemoveTag = useCallback(
    (type: CALENDAR_FILTER_ENUM, value: string) => {
      if (smartLoading) return;
      if (type === CALENDAR_FILTER_ENUM.locations) {
        setCheckedFilters(prev => ({
          ...prev,
          locations: prev.locations.filter(location => location.value !== value)
        }));
        setDefaultLocations(prev =>
          prev.filter(location => location.value !== value)
        );
      }
      if (type === CALENDAR_FILTER_ENUM.dayOfWeeks) {
        setCheckedFilters(prev => ({
          ...prev,
          dayOfWeeks: prev.dayOfWeeks.filter(day => day.value !== value)
        }));
        setDefaultDayOfWeeks(prev => prev.filter(day => day.value !== value));
      }
      if (type === CALENDAR_FILTER_ENUM.clinics) {
        setCheckedFilters(prev => ({
          ...prev,
          clinics: prev.clinics.filter(day => day.value !== value)
        }));
        setDefaultClinics(prev => prev.filter(day => day.value !== value));
      }
      if (type === CALENDAR_FILTER_ENUM.specialities) {
        setCheckedFilters(prev => ({
          ...prev,
          specialities: prev.specialities.filter(day => day.value !== value)
        }));
        setDeafultSpecialities(prev => prev.filter(day => day.value !== value));
      }
      if (type === CALENDAR_FILTER_ENUM.departments) {
        setSelectedProvidersList(prev => {
          const newSet = new Set(prev);
          newSet.delete(parseInt(value));
          return newSet;
        });
        setCheckedFilters(prev => {
          const newProvidersSet = new Set(prev.providers);
          newProvidersSet.delete(parseInt(value));

          return {
            ...prev,
            providers: newProvidersSet
          };
        });
      }
    },
    [smartLoading]
  );
  const providersData = smartResults.openingCards?.map(
    result => result.provider
  );
  const filteredProviders: IProvider[] = Array.from(
    new Set(providersData?.map(provider => provider?.id))
  )?.map(
    providerId =>
      providersData?.find(provider => provider?.id === providerId) as IProvider
  );
  const providersObject = filteredProviders.reduce((acc, provider) => {
    acc[provider.id!] = provider;
    return acc;
  }, {} as Record<number, IProvider>);
  const disabledDate = (current: Date) => {
    return (
      current &&
      moment(moment(current).format('YYYY-MM-DD')).isBefore(
        moment(moment(reqData.data?.startDate).format('YYYY-MM-DD'))
      )
    );
  };
  return (
    <RecurringSSFilterInputWrapper>
      <Grid className="grid-cls">
        <Grid.Row style={{ paddingTop: '0px' }}>
          <Grid.Column width={10} className="toggle-cls">
            <div className="toggle-rectangle">
              <div>
                <div className="label-title "> Within Client Availability</div>
              </div>
              <Radio
                toggle
                onChange={onClientAvailabilityToggle}
                disabled={smartLoading || shouldPerformAutoFilterAdjustment}
                checked={clientAvailabilityToggle}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={5} className="toggle-cls">
            <div className="toggle-rectangle">
              <div>
                <div className="label-title "> Adjacent</div>
              </div>
              <Radio
                toggle
                onChange={onAdjacentToggle}
                checked={adjacentToggle}
                disabled={
                  smartLoading || isAba || shouldPerformAutoFilterAdjustment
                }
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className="grid-cls">
        <Grid.Row style={{ paddingBottom: '0px', paddingTop: '0px' }}>
          <Grid.Column width={7}>
            <Controller
              name="startDate"
              control={methods.control}
              render={fieldProps => {
                return (
                  <Datepicker
                    className="datapicker-style"
                    name="selectedStartDate"
                    placeholder="Select Start Date"
                    format="MM/DD/YYYY"
                    field={fieldProps}
                    disabled={smartLoading || shouldPerformAutoFilterAdjustment}
                    value={reqData.startDate ? moment(reqData.startDate!) : ''}
                    onChange={startDateHandler}
                    errors={methods.errors}
                    allowClear={false}
                    disabledDate={disabledDate}
                  />
                );
              }}
            />
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={8}>
            <CheckListWrapper
              visible={filterDropdownExpand.filter}
              height="300px"
              style={{ position: 'relative', marginTop: '0px' }}
            >
              <div
                ref={moreFiltersRef}
                onBlur={e => handleGenericBlur(e, 'filter')}
                tabIndex={-1}
              >
                <div
                  className="filter-input_wrapper"
                  onClick={toggleSpecialtyList}
                >
                  <Input
                    className="filter_input"
                    placeholder="More Filters"
                    onChange={event =>
                      handleGenericSearch(
                        event,
                        { value: event.currentTarget.value },
                        'filter'
                      )
                    }
                  />
                  {!filterDropdownExpand.filter && (
                    <FontAwesomeIcon className="icon" icon={faCaretDown} />
                  )}
                  {filterDropdownExpand.filter && (
                    <FontAwesomeIcon
                      className="icon"
                      icon={faCaretDown}
                      style={{ opacity: 0 }}
                    />
                  )}
                </div>

                {filterDropdownExpand.filter && (
                  <>
                    <searchValueContext.Provider value={searchValue.filter}>
                      <div className="list_wrapper filter_list">
                        <RecurringSSFilter
                          handleSearchedValues={handleSpecialtySearchedValues}
                          setDefaultDayOfWeeks={setDefaultDayOfWeeks}
                          defaultDayOfWeeks={defaultDayOfWeeks}
                          setDefaultLocations={setDefaultLocations}
                          defaultLocations={defaultLocations}
                          setFilterDropdownExpand={setFilterDropdownExpand}
                          smartResultsData={smartResults.openingCards}
                          selectedProvidersList={selectedProvidersList}
                          setSelectedProvidersList={setSelectedProvidersList}
                          setCheckedFilters={setCheckedFilters}
                          checkedFilters={checkedFilters}
                          setDefaultClinics={setDefaultClinics}
                          deafultClinics={deafultClinics}
                          setDeafultSpecialities={setDeafultSpecialities}
                          deafultSpecialities={deafultSpecialities}
                          availableDayOfWeeks={availableDayOfWeeks}
                          availableLocations={availableLocations}
                        ></RecurringSSFilter>
                      </div>
                    </searchValueContext.Provider>
                  </>
                )}
              </div>
            </CheckListWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <FilterTagListWrapper
        style={{ marginBottom: '0px', marginLeft: '0px', marginRight: '0px' }}
      >
        <div className="tags-container pt-10" style={{ width: '100%' }}>
          {Array.from(checkedFilters.providers).length > 0 &&
            Array.from(checkedFilters.providers).map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.departments}
                  label={providersObject[f].name!}
                  handleRemoveTag={handleRemoveTag}
                  value={f.toString()}
                />
              );
            })}
          {checkedFilters.locations.length > 0 &&
            checkedFilters.locations.map(f => {
              const locationTypeCount =
                f.value === inclinicType.value
                  ? openingCards.filter(card => card.isInClinic).length
                  : f.value === offsiteType.value
                  ? openingCards.filter(card => card.isOffsite).length
                  : openingCards.filter(card => card.isTelehealth).length;
              const labelName = `${f.label} (${locationTypeCount})`;
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.locations}
                  label={labelName}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.dayOfWeeks.length > 0 &&
            checkedFilters.dayOfWeeks.map(f => {
              const dayLabel = f.label + 's';
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.dayOfWeeks}
                  label={dayLabel}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.clinics.length > 0 &&
            checkedFilters.clinics.map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.clinics}
                  label={f.label}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
          {checkedFilters.specialities.length > 0 &&
            checkedFilters.specialities.map(f => {
              return (
                <Tag
                  type={CALENDAR_FILTER_ENUM.specialities}
                  label={f.label}
                  handleRemoveTag={handleRemoveTag}
                  value={f.value}
                />
              );
            })}
        </div>
      </FilterTagListWrapper>
    </RecurringSSFilterInputWrapper>
  );
};
export default React.memo(RecurringSSFilterInput);
